export default {
    zh:{
        oaApply:{
            queryParam:{
                cyrq:'出运日期',
                cyrq_placeholder:'请选择出运日期',
                shdqr:'收汇到期日',
                shdqr_placeholder:'请选择收汇到期日',
            },
            columns:{
                F_CYRQ:'出运日期',
                F_SHDQR:'收汇到期日',
            },
            form:{
                zxbId:'OA中信保ID',
                zxbId_placeholder:'请选择OA中信保ID',
                zxbId_rule: '请选择OA中信保ID',
                cyrq:'出运日期',
                cyrq_placeholder:'请选择出运日期',
                cyrq_rule: '请选择出运日期',
                shdqr:'收汇到期日',
                shdqr_placeholder:'请选择收汇到期日',
                shdqr_rule: '请选择收汇到期日',
            },
            OaApplyDetail:{
                F_PAY_TYPE:'付款类型',
                F_PAY_TYPE_PLACEHOLDER:'请选择付款类型',
            },
        }
    },
    en:{
        oaApply:{
            queryParam:{
                cyrq:'出运日期',
                cyrq_placeholder:'请选择出运日期',
                shdqr:'收汇到期日',
                shdqr_placeholder:'请选择收汇到期日',
            },
            columns:{
                F_CYRQ:'出运日期',
                F_SHDQR:'收汇到期日',
            },
            form:{
                zxbId:'OA中信保ID',
                zxbId_placeholder:'请选择OA中信保ID',
                zxbId_rule: '请选择OA中信保ID',
                cyrq:'出运日期',
                cyrq_placeholder:'请选择出运日期',
                cyrq_rule: '请选择出运日期',
                shdqr:'收汇到期日',
                shdqr_placeholder:'请选择收汇到期日',
                shdqr_rule: '请选择收汇到期日',
            },
            OaApplyDetail:{
                F_PAY_TYPE:'付款类型',
                F_PAY_TYPE_PLACEHOLDER:'请选择付款类型',
            },
        }
    }
}